import { GetStaticProps } from 'next'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import tw from 'twin.macro'

import { Page as PageTypes } from '@/types/page'

import { getCollection, useSingle, getSingle } from '@/hooks/use-cms-data'
import { getJackpots } from '@/hooks/use-jackpots-data'

import { Container } from '@/atoms/container'
import { SeoHead } from '@/atoms/seo-head'

import { ContentZone } from '@/molecules/content-zone'
import { TPBPressForm } from '@/tpb/molecules/press-form'
import { CrockfordsPressForm } from '@/crockfords/molecules/press-form'
import { useEffect } from 'react'

// The press form is only used on Corporate > Press Form therefore
// dynamically load in on that page
const PressForm = dynamic(() =>
  import('../../../components/molecules/press-form').then(
    (mod) => mod.PressForm as any,
  ),
)

export default function Page({
  query,
  website,
  slug,
  preview,
}: {
  query: {
    slug: string
    filters: {
      applications: {
        slug: string
      }
    }
  }
  website: string
  slug: string
  preview: boolean
}) {
  const { data: pageData } = useSingle('pages', query, preview)
  const { page_title, page_meta, content_zone } =
    pageData && pageData.attributes

  const blocks = [...content_zone]

  if (website === 'the-palm-beach' && slug === 'restaurant') {
    blocks.splice(2, 0, { id: 0, __component: 'casino-navigation' })
  }

  useEffect(() => {
    // add script after page load
    const el = document.getElementById('opentable')
    // check if el has a script tag already
    if (el?.querySelector('script')) return

    const script = document.createElement('script')
    script.src =
      '//www.opentable.co.uk/widget/reservation/loader?rid=206328&type=standard&theme=standard&color=3&dark=false&iframe=true&domain=couk&lang=en-GB&newtab=false&ot_source=Restaurant%20website&cfe=true'
    script.async = true
    el?.appendChild(script)
  }, [])

  return (
    <>
      <SeoHead
        seo_title={page_meta?.seo_title || page_title}
        seo_description={page_meta?.seo_description}
        seo_canonical={page_meta?.canonical_url || `${slug}/`}
        share_title={page_meta?.social_share?.share_title}
        share_description={page_meta?.social_share?.share_description}
        share_image={page_meta?.social_share?.share_image}
      />

      <div
        css={[
          ['media-enquiries'].includes(slug) &&
            website === 'genting-casinos' &&
            tw`bg-[#F0F0F0] pb-4`,
          ['media-enquiries'].includes(slug) &&
            ['the-palm-beach', 'crockfords'].includes(website) &&
            tw`bg-theme-gold bg-diamonds-pattern pb-4`,
          ['restaurant', 'lounge-bar'].includes(slug) &&
            website === 'the-palm-beach' &&
            tw`bg-theme-secondary pb-4`,
        ]}>
        <>
          <ContentZone blocks={blocks} website={website} />

          {website === 'the-palm-beach' && slug === 'restaurant' && (
            <div id="book">
              <Container
                className="py-10 bg-black flex flex-col justify-center align-center items-center"
                variant="full">
                <p className="mb-1 ">The Palm Beach</p>
                <h1 className="uppercase text-4.5xl font-light tracking-widest leading-14">
                  <span>Book Now</span>
                </h1>
                <hr className="my-8 mx-auto border-b w-[12rem] bg-white border-theme-primary" />
                <div id="opentable" className=""></div>
              </Container>
            </div>
          )}

          {slug === 'media-enquiries' && website === 'genting-casinos' ? (
            <PressForm />
          ) : null}
          {slug === 'media-enquiries' && website === 'the-palm-beach' ? (
            <TPBPressForm />
          ) : null}
          {slug === 'media-enquiries' && website === 'crockfords' ? (
            <CrockfordsPressForm />
          ) : null}
        </>
      </div>
    </>
  )
}

export async function getStaticPaths() {
  const websites = await getCollection('applications')

  const paths: any = []
  for (const website of websites.data) {
    const { data } = await getCollection('pages', {
      pagination: {
        pageSize: 100,
      },
      filters: {
        applications: {
          slug: website.attributes.slug,
        },
      },
    })

    data.map((page: PageTypes) => {
      if (
        [
          'sitemap',
          'news',
          'whats-on',
          'entertainment',
          'casino-gaming',
          'casinos',
          'faqs',
        ].includes(page.attributes.slug)
      ) {
        return
      }

      // Based on this https://www.codeconcisely.com/posts/pre-rendering-routes-with-multiple-parameters-in-nextjs/
      // moving all routes inside a dynamic [website] directory. For main retail website rewqirte URLS to not include
      // geting-casinos. The London clubs will include the pathname

      paths.push({
        params: {
          website: website.attributes.slug,
          slug: page.attributes.slug,
        },
      })
    })
  }

  return {
    /* @ts-ignore */
    paths,
    fallback: false,
  }
}

export const getStaticProps: GetStaticProps = async ({
  params,
  preview = false,
}) => {
  const { slug, website } = params

  const filters = {
    applications: {
      slug: website,
    },
  }

  const query = { slug, filters }
  const queryClient = new QueryClient()

  // Do we need to fetch applications server side?
  await queryClient.prefetchQuery(['applications'], () =>
    getCollection('applications'),
  )

  // Page content - now with added filter for
  await queryClient.prefetchQuery(['pages', query], () =>
    getSingle('pages', query, preview),
  )

  // navigation
  await queryClient.prefetchQuery(['navigations', { filters }], async () =>
    getSingle('navigations', { filters }),
  )

  await queryClient.prefetchQuery('jackpots', async () => {
    return await getJackpots()
  })

  // News grid used on homepage and about page
  // only prefetch for certain slugs
  if (['about-genting-uk', 'home-genting-casinos'].includes(slug as string)) {
    const newsQuery = {
      filters,
      pagination: {
        pageSize: 20,
        page: 1,
      },
      sort: 'article_date:DESC',
    }
    await queryClient.prefetchQuery(['news-articles', newsQuery], () =>
      getCollection('news-articles', newsQuery),
    )
  }

  return {
    props: {
      query,
      slug,
      website,
      dehydratedState: dehydrate(queryClient),
      preview,
    },
  }
}
