import React from 'react'
import tw from 'twin.macro'

type Props = {
  id: string,
  inputRef: React.RefCallback<HTMLInputElement>
}

function ConfirmCheckbox({
  id,
  inputRef,
  ...rest
} : Props) {
  return (
    <input
      type="checkbox"
      id={id}
      tw="flex-shrink-0 mr-1.5 focus:(outline-none ring-2 ring-red-500 border-red-500) h-4 w-4 text-theme-accent border-theme-secondary"
      ref={inputRef}
      {...rest}
    />    
  )
}

export { ConfirmCheckbox }
