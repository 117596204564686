import { useState } from 'react'
import Script from 'next/script'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import tw, { css, theme } from 'twin.macro'

import { Form as FormTypes } from '@/types/press-form'

import config from '@/lib/config'
import { validationSchema } from '@/tpb/lib/media-enquiries-schema'
import { enquiryTypes } from '@/lib/contact-enquiry-schema'
import { getRecaptchaKey } from '@/lib/recaptcha'

import { Button } from '@/atoms/button'
import { ConfirmCheckbox } from '@/tpb/atoms/confirm-checkbox'
import { Container } from '@/atoms/container'
import { FieldError } from '@/atoms/field-error'
import { Heading } from '@/atoms/headings'
import { Label } from '@/atoms/label'
import { SelectField } from '@/atoms/select-field'
import { Spinner } from '@/atoms/spinner'
import { TextareaField } from '@/atoms/textarea-field'
import { TextField } from '@/atoms/text-field'

import { SuccessModal } from '@/molecules/success-modal'
import { ErrorModal } from '@/molecules/error-modal'
import { NextLink } from '@/atoms/next-link'
import { GoogleRecaptchaText } from '@/atoms/google-recaptcha'

function TPBPressForm() {
  const [loading, setLoading] = useState<boolean>(false)
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false)
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)

  const { captchaSiteKey } = config

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<FormTypes>({
    mode: 'onBlur', // "onChange"
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<FormTypes> = async (data) => {
    setLoading(true)

    const recaptchaToken = await getRecaptchaKey()

    const res = await fetch('/api/media-enquiries', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'recaptcha_token': recaptchaToken,
      },
      body: JSON.stringify(data),
    })

    if (res.status === 200) {
      setSuccessModalOpen(true)
    } else {
      const errors = await res.json()

      if (errors.inner) {
        errors.inner.forEach(
          ({ path, message }: { path: string; message: string }) => {
            /* @ts-ignore */
            setError(path, {
              type: 'server',
              message: message,
            })
          }
        )
      }

      setErrorModalOpen(true)
    }

    setLoading(false)
  }

  return (
    <>
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${captchaSiteKey}`}
        id="recaptcha-js"
        strategy="lazyOnload"
      />
      <Container>
        <SuccessModal open={successModalOpen} setOpen={setSuccessModalOpen} />
        <ErrorModal open={errorModalOpen} setOpen={setErrorModalOpen} />
        <section
          css={[
            tw`md:(grid gap-8) text-theme-inverse mb-24`,
            css`
              @media screen and (min-width: ${theme('screens.md')}) {
                grid-template-areas: 'address form';
                grid-template-columns: 258px 1fr;
              }

              @media screen and (min-width: ${theme('screens.lg')}) {
                grid-template-areas: 'address form blank';
                grid-template-columns: 258px 1fr 258px;
              }
            `,
          ]}>
          <main
            css={[
              tw`py-16 px-5 mb-8 md:(px-12 mb-0) h-full rounded-xl bg-white`,
              css`
                grid-area: form;
              `,
            ]}>
            <form data-testid="press-form" onSubmit={handleSubmit(onSubmit)}>
              <fieldset className="mb-14" disabled={loading}>
                <Heading variant="h3" tw="mb-8 text-theme-accent text-center">
                  My Details
                </Heading>
                <div className="grid grid-cols-6 gap-x-0 sm:gap-x-10 md:gap-x-10 lg:gap-x-20 gap-y-8">
                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="first-name" required={true}>
                      First name
                    </Label>
                    <TextField
                      autoComplete="given-name"
                      placeholder="First Name"
                      {...register('firstName')}
                    />
                    {errors?.firstName && (
                      <FieldError
                        name="firstName"
                        message={errors.firstName.message}
                      />
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="last-name" required={true}>
                      Last name
                    </Label>
                    <TextField
                      autoComplete="family-name"
                      placeholder="Last Name"
                      {...register('lastName')}
                    />
                    {errors?.lastName && (
                      <FieldError
                        name="lastName"
                        message={errors.lastName.message}
                      />
                    )}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="email-address" required={true}>
                      Email
                    </Label>
                    <TextField
                      type="email"
                      autoComplete="email"
                      placeholder="Email Address"
                      {...register('email')}
                    />
                    {errors?.email && (
                      <FieldError name="email" message={errors.email.message} />
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="contact-number" required={true}>
                      Contact Number
                    </Label>
                    <TextField
                      type="tel"
                      autoComplete="tel-national"
                      placeholder="Phone Number"
                      {...register('telephone')}
                    />
                    {errors?.telephone && (
                      <FieldError
                        name="telephone"
                        message={errors.telephone.message}
                      />
                    )}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="company-name" required={true}>
                      Company Name
                    </Label>
                    <TextField
                      autoComplete="organization"
                      placeholder="Company Name"
                      {...register('organization')}
                    />
                    {errors?.organization && (
                      <FieldError
                        name="organization"
                        message={errors.organization.message}
                      />
                    )}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="nature-of-enquiry" required={true}>
                      Nature of enquiry
                    </Label>
                    <SelectField
                      placeholder="Nature of enquiry"
                      {...register('enquiryType')}>
                      {enquiryTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </SelectField>
                    {errors?.enquiryType && (
                      <FieldError
                        name="enquiryType"
                        message={errors.enquiryType.message}
                      />
                    )}
                  </div>
                </div>
              </fieldset>
              <fieldset disabled={loading}>
                <Heading variant="h3" tw="mb-8 text-theme-accent text-center">
                  My Enquiry
                </Heading>
                <div className="grid grid-cols-6 gap-x-0 md:gap-x-10 lg:gap-x-20 gap-y-8">
                  <div className="col-span-6">
                    <Label htmlFor="additionalDetails" required={true}>
                      Additional details
                    </Label>
                    <TextareaField
                      placeholder="Add your message here"
                      {...register('additionalDetails')}
                    />
                    {errors?.additionalDetails && (
                      <FieldError
                        name="additionalDetails"
                        message={errors.additionalDetails.message}
                      />
                    )}
                  </div>
                  <div className="col-span-6">
                    <Controller
                      name="terms"
                      control={control}
                      render={({ field }) => (
                        <ConfirmCheckbox
                          id="terms"
                          inputRef={field.ref}
                          {...field}
                        />
                      )}
                    />
                    <Label
                      htmlFor="terms"
                      required={true}
                      tw="inline-block w-[calc(100% - 22px)] align-text-top">
                      I have read and agree to{' '}
                      <NextLink
                        to="/the-palm-beach/terms-and-conditions/"
                        tw="hover:text-theme-accent">
                        terms and conditions
                      </NextLink>
                      .
                    </Label>
                    {errors?.terms && (
                      <FieldError name="terms" message={errors.terms.message} />
                    )}
                  </div>
                </div>
              </fieldset>

              <div className="mt-10 md:mt-12 grid grid-cols-6 justify-center">
                <Button
                  type="submit"
                  theme="accent"
                  variant="contained"
                  disabled={loading}
                  tw="uppercase col-span-6 md:(col-span-2 col-start-3) flex h-full justify-center items-center font-light text-sm">
                  {loading ? <Spinner margin="none" /> : 'Get in touch'}
                </Button>
              </div>
              <GoogleRecaptchaText />
            </form>
          </main>
          <aside
            css={[
              tw`py-16 px-8 h-full rounded-xl bg-white`,
              css`
                grid-area: address;
              `,
            ]}>
            <Heading variant="h4" tw="mb-10 text-theme-accent">
              Genting UK PLC
            </Heading>

            <address className="not-italic">
              <p className="mb-10">
                43-45 Cromwell Rd
                <br />
                South Kensington
                <br />
                London SW7 2EF
                <br />
                UK
                <br />
              </p>
            </address>
            <img
              className="mb-8 md:mb-14"
              alt="Location map"
              src="/placeholders/corporate_press_map.jpg"
              width="412"
              height="206"
            />
            <p>
              For Casino enquiries please visit{' '}
              <NextLink
                to="/the-palm-beach/contact-us"
                tw="hover:text-theme-accent">
                The Palm Beach contact page
              </NextLink>
            </p>
          </aside>
        </section>
      </Container>
    </>
  )
}

export { TPBPressForm }
