import * as yup from "yup";

export const enquiryTypes = [
  'Planning a visit',
  'A previous visit',
  'My Genting App',
  'Membership',
  'Rewards & Offers',
  'Other',
]

export const validationSchema = yup.object().shape({
  firstName: yup.string()
    .required('First name is required')
    .max(80, 'First name is too long'),
  lastName: yup.string()
    .required('Last name is required')
    .max(80, 'Last name is too long'),
  email: yup.string()
    .required('Email address is required')
    .max(200, 'Email address is too long')
    .matches(
      /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
      {
        message: 'Email address is not valid',
        excludeEmptyString: true
      }
    ),    
  telephone: yup.string()
    .required('Contact number is required')
    .matches(
      /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/,
      'Contact number is not valid'
    ),    
  organization: yup.string()
    .required('Company name is required')
    .max(80, 'Company name is too long'),
  enquiryType: yup.string()
    .required('Nature of enquiry is required')
    .oneOf(enquiryTypes, 'Enquiry option not found, please try another'),
  additionalDetails: yup
    .string()
    .required('Additional details are required'),
  terms: yup
    .boolean()
    .notOneOf([undefined], 'You have to agree with terms and conditions')
    .oneOf([true], 'You have to agree with terms and conditions')
}).required();